import React from "react";
import classes from './Footer.module.scss';
import logo from '../../../assets/img/logo.svg';
import bg from '../../../assets/img/Footer/bg.webp';
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    const { hostname } = document.location;
    return (
        <footer className={["container", classes.container, "mt"].join(' ')}>
            <img className={classes.bg} src={bg} alt='' />
            <div className={classes.footer}>
                <div className={classes.logo}>
                    <img src={logo} alt='' />
                </div>
                <div className={classes.links}>
                    <div className={classes.linksItem}>
                        <a href="tel:+441241340820">
                            +441241340820
                        </a>
                    </div>
                    <div className={classes.linksItem}>
                        <a href={`mailto:support@${hostname}`}>
                            support@{hostname}
                        </a>
                    </div>
                    <div className={classes.linksItem}>
                        <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                            {t('footer.docs.terms')}
                        </a>
                    </div>
                    <div className={classes.linksItem}>
                        <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                            {t('footer.docs.privacy')}
                        </a>
                    </div>
                    <div className={classes.linksItem}>
                        <a href="/docs/AML_POLICY.pdf" target="_blank">
                            {t('footer.docs.aml')}
                        </a>
                    </div>
                </div>
                <div className={classes.disclaimer}>
                    <div>
                        <p className={classes.disclaimerTitle}>
                            {t('footer.discl.title')}
                        </p>
                        <div className={classes.disclaimerText}>
                            <p>
                                <Trans>
                                    {t('footer.discl.text')}
                                </Trans>
                            </p>
                        </div>
                    </div>
                    <div className={classes.rights}>
                        {t('footer.rights')}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
